
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IWarehouseListRequest, IWarehouse } from "@/types/warehouse";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // warehouse
  @Action("warehouse/adminGetList")
  public getList!: (request: IWarehouseListRequest) => boolean;

  @Getter("warehouse/list")
  public warehouseList!: IWarehouse[];

  @Getter("warehouse/pagination")
  public pagination!: IPagination<IWarehouse[]>;

  @Mutation("warehouse/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: IWarehouseListRequest = {
    page: 1,
    search: ""
  };

  //
  // variables
  //
  public headers = [
    { text: "倉庫名", value: "name", sortable: false },
    { text: "郵便番号", value: "postalcode", sortable: false },
    { text: "住所", value: "address", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();

    await this.getList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1,
      search: query.search ? decodeURI(query.search as string) : ""
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1",
          search: this.params.search
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(warehouse: IWarehouse) {
    this.$router.push(`/admin/warehouse/${warehouse.id}/edit`);
  }
}
